import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const Learn = () => ( <Layout>
  <div>
    <Helmet
      title={'temtum Learn | Cryptocurrency TEM'}
      meta={[
        {
          name: 'description',
          content: 'temtum (TEM) is a market leading cryptocurrency, founded by Richard Dennis, developed on the Temporal Blockchain from Dragon Infosec.'
        }
      ]}
    />
    <div className="hd-space"/>
    <section className="text-white skipScroll bg-blue-3 bg-learn">
      <div className="wow video-overlay"/>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="display-1  pb-4">Learn</h2>
            <p className="lead">
              More about Temporal Blockchain and TEM coin.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="learn">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mx-auto text-center">
                  <h2 className="display-4 mb-3">
                    With unrivalled knowledge on existing Blockchain security, speed and scalability
                  </h2>
                  issues and peer to peer networks from Tor to OpenBazaar, the temtum team regularly provide deep insight across our documentation, blog and published papers. We provide insight, research and answers to some of the industries most troubling questions, while keeping all updated on the progress of our Temporal Blockchain and temtum.
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>Blockchain to Platform</h3>
                        <p className="card-text">
                          Explore the Temporal Blockchain that underpins the temtum cryptocurrency and find out how it works.
                        </p>
                        <Link to="/platform" className="btn btn-outline-primary btn-sm">
                          About the platform
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>Beacon</h3>
                        <p className="card-text">
                          The beacon uses quantum effects to generate a sequence of truly random values, guaranteed to be unpredictable.
                        </p>
                        <Link to="/beacon" className="btn btn-outline-primary btn-sm">
                          About the beacon
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>White Paper</h3>
                        <p className="card-text">
                          Download our White Paper for an in depth overview of temtum and our Temporal Blockchain technology.
                        </p>
                        <Link to="/whitepaper" className="btn btn-outline-primary btn-sm scroll">
                          Read White Paper
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>Articles</h3>
                        <p className="card-text">
                          Our tech and cryptography teams often provide industry leading insight across crypto, cybersecurity and blockchain technology.
                        </p>
                        <Link to="/press" className="btn btn-outline-primary btn-sm">
                          Visit the Press
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>Research Papers</h3>
                        <p className="card-text">
                          Our academic team have numerous published papers on peer to peer networks, cryptography and blockchain technology available here.
                        </p>
                        <Link to="/research" className="btn btn-outline-primary btn-sm">
                          View research
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>FAQs</h3>
                        <p className="card-text">
                          The most asked questions about temtum are answered in our FAQ section.
                        </p>
                        <Link to="/faq" className="btn btn-outline-primary btn-sm">
                          Read FAQs
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>About Us</h3>
                        <p className="card-text">
                          Come and meet our industry leading team with a rich history in cryptography and the commercial sectors.
                        </p>
                        <Link to="/about" className="btn btn-outline-primary btn-sm">
                          Read about us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>Discord</h3>
                        <p className="card-text">
                          Join us on Discord to discuss technical considerations from the white paper with our community team.
                        </p>
                        <a href="https://discordapp.com/invite/hs3v4g4" className="btn btn-outline-primary btn-sm" target="_blank" rel="noopener noreferrer">
                          Join our channel
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <h3>Telegram</h3>
                        <p className="card-text">
                          Be involved in our Telegram community to keep up with our progress , ask questions and engage with our like minded community.
                        </p>
                        <a href="https://t.me/Temtumofficial" className="btn btn-outline-primary btn-sm" target="_blank" rel="noopener noreferrer">
                          Join our channel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</Layout> );
export default Learn;
